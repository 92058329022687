
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { tools, OnPageTo } from "src/utils";
import { UserService } from "src/services";
@Component
export default class ProductPage extends Vue
{
    // 用于刷新子组件
    private keyNum: number = 0;

    // 样式
    private pageStyle: any = {};

    /**
     * 是否返回套餐id
     * @private
     * @returns string
     */
     @Prop({})
    private packageId: any;

    // 标题样式
    private titleStyle: any = {};

    // 副标题样式
    private subTitleStyle: any = {};

    /**
     * 父组件传值
     * @private
     * @returns string
     */
    @Prop({type: Object, default: {}})
    private data: any;

    /**
     * 父组件传值
     * @private
     * @returns string
     */
     @Prop({type: Boolean})
    private isEditStatus: any;

    /**
     * 是否展示预约须知
     * @private
     * @returns string
     */
     @Prop({type: Boolean})
    private isShowNotice: any;

    /**
     * 个人信息
     * @private
     * @returns string
     */
     @Prop({type: Object})
    private personalUser: any;

    /**
     * 跳转小程序dom节点内容
     * @private
     * @returns string
     */
    private reportContent: string = `
        <button class="test-btn"></button>
        <style>
            .test-btn{
                width: 100%;
                height: 520px;
                background: transparent;
                border: none;
                color:rgba(255,255,255,0)
            }
            .test-btn:focus{outline: 0}
        </style>
    `;

    /**
     * 页面跳转 
     * @private
     */
     private onPageTo(item: any)
    {
        OnPageTo(item, this);
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get userInfo(): any {
        let userInfoStorge = localStorage.getItem("userInfo");

        if (userInfoStorge) {
        return JSON.parse(userInfoStorge);
        }

        return {};
    }

    /**
     * 微信跳转
     * @private
     * @returns void
     */
    private minWxTo() {
        this.$nextTick(async (): Promise<void> => {
            try {
                let { content: result } = await UserService.instance.jump2Personal();
                if (result && result.data) {
                    for (let i = 0; i < document.getElementsByClassName("minWx-title-class").length; i++)
                    {
                        let domId = document.getElementsByClassName("minWx-title-class")[i].getAttribute("id");
                        let type = +document.getElementsByClassName("minWx-title-class")[i].getAttribute("t");
                        let url = document.getElementsByClassName("minWx-title-class")[i].getAttribute("u");
                        let appId = document.getElementsByClassName("minWx-title-class")[i].getAttribute("a");
                        // 初始化微信dom     
                        this.initWx({type: type, url: url, appId: appId, domId: domId}, result);
                    }
                }
            } catch (err) {
                console.log(err);
            }
        })
    }

    /**
     * 跳转个人版，获取Token的Key
     * @private
     * @returns void
     */
    private async initWx(data, result): Promise<void> {
        if (data.type == 405)   // 健康档案
        {
            // 健康档案
            let healthRecord = {
                appid: "gh_387fc8fa08dd",
                eleId: data.domId, // 元素id
                url: `/pages/health-records/index?k=${result.data.key}&uId=${this.userInfo.genUserId}&i=wx41259acafe15eaeb&u=/pages/health-records/index`, // 跳转小程序的页面路径
                content: this.reportContent, // 自定义的html内容
            };

            this.wx_launch(healthRecord);
        }
        else if (data.type == 406)  // 报告解读
        {
            // 报告解读 -  拿到最新一条未解读数据
            try {
            let { content: resultData } = await UserService.instance.queryNotSumbitAnalyzeReport();
                let reportParams = {
                appid: "gh_387fc8fa08dd",
                eleId: data.domId, // 元素id
                url: `/pages/health-records/index?k=${result.data.key}&uId=${this.userInfo.genUserId}&i=wx41259acafe15eaeb&u=/pages/report/list`, // 跳转小程序的页面路径
                content: this.reportContent // 自定义的html内容
            };
            if (resultData && resultData.data && resultData.data.id)
            {
                reportParams.url = `/pages/health-records/index?k=${result.data.key}&uId=${this.userInfo.genUserId}&i=wx41259acafe15eaeb&u=/pages/report/decode?id=${resultData.data.id}`;
            }
            this.wx_launch(reportParams);
            } catch (err) {
                console.log(err);
            }
        }
        else if (data.appId == "gh_387fc8fa08dd")  // 个人版小程序
        {
            let personalParams = {
                appid: data.appId,
                eleId: data.domId, // 元素id
                url: `/pages/health-records/index?k=${result.data.key}&uId=${this.userInfo.genUserId}&i=wx41259acafe15eaeb&u=${data.url}`, // 跳转小程序的页面路径
                content: this.reportContent // 自定义的html内容
            };
            this.wx_launch(personalParams);
        }
        else
        {
            let personalParams = {
                appid: data.appId,
                eleId: data.domId, // 元素id
                url: `${data.url}`, // 跳转小程序的页面路径
                content: this.reportContent // 自定义的html内容
            };
            this.wx_launch(personalParams);
        }
    }

    /**
     * 跳转小程序封装
     * @protected
     * @returns void
     */
    private async wx_launch(info): Promise<void> {
        if (!(await tools.isWechat())) {
            return;
        }
        var btn = document.getElementById(info.eleId); //获取元素
        if (!btn) {
            return;
        }
        let script = document.createElement("script"); // 创建script内容插槽 避免template标签冲突
        script.type = "text/wxtag-template"; // 使用script插槽 必须定义这个type
        script.text = info.content; // 自定义的html字符串内容
        let html = `<wx-open-launch-weapp style="width:100%; height:100%;display:block;overflow: hidden;" username="${info.appid}" path="${info.url}">${script.outerHTML}</wx-open-launch-weapp>`;
        btn.innerHTML = html; // html字符串赋值
        // 点击按钮 正常跳转触发
        btn.addEventListener("launch", function (e) {
            console.log("success");
        });
        // 点击跳转 抛出异常
        btn.addEventListener("error", function (e) {
            console.log("fail", e);
            alert(`跳转异常 - ${JSON.stringify(e)}`);
        });
    }

    /**
     * 值发生变化
     * @private
     * @returns void
     */
    @Watch("data",{immediate: true, deep: true})
    private onDataChange(value: any): void
    {
        function px2rem(px){
            if(/%/ig.test(px)){
                return px;
            }else{
                return ((parseFloat(px) / 75 * 2).toFixed(5)) + 'rem';
            }
        }
        if(value)
        {
            let marginLeftOrRight = value.properties.moduleSettings.leftAndRight === 0 ? 0 : (value.properties.moduleSettings.leftAndRight || 16);
            this.pageStyle = {'margin': value.properties.moduleSettings.upAndDown + 'px ' + px2rem(marginLeftOrRight) + ' 0 ' + px2rem(marginLeftOrRight), 
                    'background-color': value.properties.moduleSettings.bgColor,
                    'border': value.properties.moduleSettings.contourLine.show ? value.properties.moduleSettings.contourLine.width + 'px solid' + value.properties.moduleSettings.contourLine.color : '',
                    'border-radius': value.properties.moduleSettings.contourLine.show && !value.properties.moduleSettings.contourLine.corner ? '6px' : ''
                    };
            this.titleStyle = {'font-size': value.properties.moduleSettings.titleFontSize + 'px',
                        'color' : value.properties.moduleSettings.titleColor,
                        'text-align': value.properties.moduleSettings.textCentered ? 'center' : 'left'};
            this.subTitleStyle = {'font-size': value.properties.moduleSettings.subTitleFontSize + 'px',
                            'color' : value.properties.moduleSettings.subTitleColor, 
                            'text-align': value.properties.moduleSettings.textCentered ? 'center' : 'left'};
            this.keyNum++;
            this.minWxTo();
        }
    }
}
    
