
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import router from "src/router";
import { EmployerNoticeService } from "src/services";
@Component
export default class ProductPage extends Vue
{
  
    // 用于刷新子组件
    private keyNum: number = 0;

    // 样式
    private pageStyle: any = {};

    // 背景样式
    private bgStyle: any = {};


    // 文字样式
    private titleStyle: any = {};

    // 小喇叭样式
    private trumpetColor: any = {};

    // 箭头颜样式
    private arrowheadColor: any = {};

    // 公告数据
    private noticeMarquee: any = {};

    /**
     * 父组件传值
     * @private
     * @returns string
     */
    @Prop({type: Object, default: {}})
    private data: any;

    /**
     * 跳转路由
     * @private
     * @returns void
     */
    private onActionTo(name: string): void {
        router.push({ name: name });
    }
    
    /**
     * 获取通知公告跑马灯列表
     * @private
     * @returns void
     */
    private async getNoticeMarqueeList(): Promise<void> {
        try {
            let { content: result } = await EmployerNoticeService.instance.getNoticeMarqueeList();
            if (result && result.data && result.data.length > 0) {
                this.noticeMarquee = result.data[0];
            }
        } catch (err) {
            console.log(err);
        }
    }

    /**
     * 值发生变化
     * @private
     * @returns void
     */
    @Watch("data",{immediate: true, deep: true})
    private onDataChange(value: any): void
    {
        if(value)
        {
            this.pageStyle = {'margin': value.properties.moduleSettings.upAndDown + 'px 0 0 0'};
            this.bgStyle = {'background-color': value.properties.moduleSettings.bgColor};
            this.titleStyle = {'color' : value.properties.moduleSettings.titleColor};
            this.trumpetColor = {'color' : value.properties.moduleSettings.trumpetColor};
            this.arrowheadColor = {'color' : value.properties.moduleSettings.arrowheadColor};
            this.getNoticeMarqueeList();
            this.keyNum++;
        }
    }
}
    
