
import { Component, Vue, Watch, Prop} from "vue-property-decorator";
import { Dialog, Toast } from "vant";
import router from "src/router";
import { UserService,OrderService,PrivacyTermService, ReportService } from "src/services";
import globalConfig from "src/config/index";
import { log } from "console";

@Component
export default class BannerPage extends Vue
{

    // 用于刷新子组件
    public keyNum: number = 0;

    // 样式
    public pageStyle: any = {};

    // 主标题字体大小
    public titleText: any = {};

    // 副标题字体大小
    public subTitleText: any = {};

    // 图片样式
    public imgStyle: any = {};

    /**
     * 是否显示权益中心
     * @private
     * @returns boolean
     */
     private hasRights: boolean = false;

    /**
     * 个人信息是否完善
     * @private
     * @returns void
     */
     private isPerfect = true;

    /**
     * 获取企业列表
     * @private
     * @returns void
     */
     public employerList = [];

    /**
     *  订单数量
     */
    private orderQuantity1:any = null
    private orderQuantity2:any = null
    private orderQuantity3:any = null

    /**
     * 获取企业列表
     * @private
     * @returns void
     */
     public async getEmployerList(data): Promise<void>
    {
        this.employerList = data;
    }

    /**
     * 显示企业列表弹框
     * @private
     * @returns void
     */
     private showEmployerPopup = false;

     private created () {
        this.getOrderQuantity()
     }

     /**
     * 关闭弹框
     * @private
     * @returns void
     */
    private onEmployerClose(): void
    {
        this.checkIsPerfect();
        this.getOrderQuantity();
    }

    /**
     * 跳转页面
     * @private
     * @returns void
     */
     private onGopage(name: string): void
    {
        this.$router.push({name});
    }

     /**
     * 查询个人信息是否完善
     * @private
     * @returns void
     */
     private async checkIsPerfect(): Promise<void>
    {
        try
        {
            let {content: result} = await UserService.instance.checkIsPerfect();
            if (result.data)
            {
                this.isPerfect = result.data.isPerfect;
                if (!result.data.isPerfect)
                {
                    Dialog.confirm({
                        message: '您的个人信息未完善，是否前去完善',
                        confirmButtonText: "立即完善",
                        cancelButtonText: "稍后完善",
                        showCancelButton:false
                    })
                    .then(() => {
                        this.$router.push({name: "perfect-user", query: {userId: this.userInfo.userId}});
                    })
                    .catch(() => {
                    });
                }
            }
        }
        catch(err)
        {
            Toast(JSON.stringify(err));
        }
    }
    
    /**
     * 获取订单数量
     * @private
     * @returns void
     */
     private async getOrderQuantity(): Promise<void>
    {
        try
        {
            let { content: result } = await OrderService.instance.getOrderQuantity();
            if(result.data)   // 订单数量
            {
                if (result.data.quantities)
                {
                    result.data.quantities.forEach((item) => {
                        if(item.status===1)
                        {
                            this.orderQuantity1 = item.quantity
                        }
                        if(item.status===2)
                        {
                            this.orderQuantity2 = item.quantity
                        }
                        if(item.status===3)
                        {
                            this.orderQuantity3 = item.quantity
                        }
                    })
                }
                this.hasRights = result.data.hasRights || false;
            }
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 父组件传值
     * @private
     * @returns string
     */
    @Prop({type: Object, default: {}})
    private data: any;

    /**
     * 值发生变化
     * @private
     * @returns void
     */
    @Watch("data",{immediate: true, deep: true})
    private onDataChange(value: any): void
    {
        if(value)
        {
            // 背景样式
        this.pageStyle= {
            'margin': value.properties.moduleSettings.upAndDown + 'px 0 0 0', 
            'background-color': value.properties.moduleSettings.navigateBgcolor,
        };

        // 主标题字体大小
        this.titleText= {
            'font-size':value.properties.moduleSettings.titleFont + 'px'
        }

        // 副标题字体大小
        this.subTitleText= {
            'font-size':value.properties.moduleSettings.subTitleFont + 'px'
        }



        this.keyNum++;
        }
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
     private get userInfo(): any
    {
        return JSON.parse(localStorage.getItem("userInfo"));
    }

    /**
     * 公司信息
     * @private
     * @returns void
     */
    private get orgInfo(): any
    {
        return this.$store.getters.orgInfo || {};
    }

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
     protected get personalCenter(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return (employerConfig && employerConfig.personalCenter) || null;
    }
}
